// import { timeSince } from "constants/cardsConstant";
import { callGaEvent } from "constants/cricket";
import React, { useState, useEffect  } from "react";

const colors = ["green", "blue", "red"];

const NewsCarousel = ({ newsData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  let touchStartX = 0;
  let touchEndX = 0;

  const [list , setList] = useState(newsData?.slice(0,5))

 // Pause auto-slide on user interaction
 const pauseAutoSlide = () => {
  setIsPaused(true);
  
  // setTimeout(() => setIsPaused(false), 5000); // Resume auto-slide after 5 seconds
};

const resumeAutoSlide = () => {
  setIsPaused(false)
}

// Handle Touch Swipe (Mobile)
const handleTouchStart = (e) => {
  touchStartX = e.touches[0].clientX;
  pauseAutoSlide();
};

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    const swipeThreshold = 50;
    if (touchStartX - touchEndX > swipeThreshold) {
      nextSlide();
    } else if (touchEndX - touchStartX > swipeThreshold) {
      prevSlide();
    }
    const obj = {
      eventName : "News_Card_Scroll",
      pageTitle : "Home Page"
    }
    callGaEvent(obj)
  };

     // Go to next slide
     const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (list.length));
    };
  
    // Go to previous slide
    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? newsData.length - 1 : prevIndex - 1
      );
    };


    const handleDotClick = (index) => {
      setCurrentIndex(index)
      const obj = {
        eventName : "News_Card_Scroll",
        pageTitle : "Home Page"
      }
      callGaEvent(obj)
    }
  useEffect(() => {
    let interval = null
    if(isPaused){
      clearInterval(interval)
      return
    }
    else{
       interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % list.length);
      }, 3000);
  
    }
    
    return () => clearInterval(interval);
  }, [isPaused]);


  const handleNewsClick = (item) => {
    const obj = {
      eventName : "News_Card_Click",
      pageTitle : "Home Page"
    }
    callGaEvent(obj)
    let type = item?.type == 'video' ? 'video'  : 'post'
    window.location.href = `/sports/${type}/${item?.postSlug}`
  }


  const onMouseEnter = () => {
    pauseAutoSlide();
  }

  const onMouseLeave = () => {
    resumeAutoSlide();
  }


  return (
    <div
      className="featuredCards col rounded rounded-4 "
      style={{
        flex: "1",
        background: "#016369",
        maxWidth: "430px",
        padding: "13px 16px",
        marginTop:"1px",
        backgroundImage: "url('/assets/images/bg-news.png')",
        backgroundPosition: "right",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()} style={{ overflow: "hidden",  }}>
        <div
          style={{
            display: "flex",
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: "transform 0.7s ease-in-out",
          }}
        >
          {list.map((item, index) => {
            return (
              <div
              onClick={() => {
                handleNewsClick(item)
              }}
                key={index}
                style={{
                  background: "transparent",
                  borderRadius: "12px",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  flexShrink: "0",
                  width: "100%",
                  height:"80px",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  cursor : "pointer"
                }}
              >
                {/* Image Section */}
                <div style={{ flexShrink: 0 }}>
                  <img
                    src={item?.headingImageUrl}
                    // src = ""
                    alt="Pakistan Cricket"
                    style={{
                      width: "100px",
                      height: "60px",
                      borderRadius: "8px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                {/* Text Content */}
                <div style={{ flex: 1, color: "white" }}>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    {item?.title}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                      opacity: 0.8,
                    }}
                  >
                    {/* <img
                  src="clock-icon.png"
                  alt="Clock Icon"
                  style={{ width: "16px", height: "16px", marginRight: "6px" }}
                /> */}

                    {/* <span style={{ fontSize: "14px" }}>
                      <i
                        className={
                          item?.type == "video"
                            ? `icon-play-circle`
                            : "icon-cat-read"
                        }
                        width="500px"
                        height="500px"
                        style={{
                          color: "white",
                          // position: "absolute",
                          // marginRight :"10px"
                          paddingRight: "0px",
                          // left: "2px",
                          // bottom: "-5px",
                          // width: "500px",
                        }}
                      ></i>{" "}
                      {item?.publishedAt
                        ? timeSince(item?.publishedAt)
                        : timeSince(item?.createdAt)}{" "}
                      ago
                    </span> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "8px",
          padding: "10px 0px",
          paddingBottom:"0"
        }}

      >
        {list.map((_, index) => (
          <button
            key={index}
            onClick={() => handleDotClick(index)}
            style={{
              width: currentIndex === index ? "10px" : "8px",
              height: currentIndex === index ? "10px" : "8px",
              borderRadius: "50%",
              border: currentIndex === index ? "none" : "1.5px solid #d1d5db",
              transition: "background-color 1s ease",
              backgroundColor:
                currentIndex === index ? "#d9368b" : "transparent",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default NewsCarousel;
